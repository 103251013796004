import React from 'react'
import WeighIn from "../components/WeighIn";

export default function WeighInPage() {
    return (
        <div class="success-box small-font">
            <div class="text-c text-center">
                <h2>Take a full-length photo of yourself</h2>
                <p>Take a video weighing yourself on a scale, with this randomized keyword on a piece of paper</p>
                <div class="form">
                    <WeighIn />
                </div>
            </div>
        </div>
    );
}
