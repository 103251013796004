import React, { useContext } from "react";

import { OnboardingContext } from "../contexts";

const CommitmentAmount = () => {
    const { data, actions } = useContext(OnboardingContext);
    if (data.currentStep !== 3 || data.commitStep !== 0) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="before-btns">
                <div className="text-c text-center">
                    <h2>Your commitment amount</h2>
                    <ul>
                        <li>If you hit your weight loss goal, you get your money back (we take a small success fee to improve the Commit program).</li>
                        <li>If you don’t, then we donate your money to your commitment nemesis—an organization that you hate.</li>
                    </ul>
                </div>
                <div className="grey-box text-center">
                    <div className="form style2">
                        <h3>How much would you like to put up to your commitment?</h3>
                        <form action="" method="post">
                            <div className="box">
                                <div className={`item-c money-c ${data.commitmentAmountFeedback.length ? 'error' : ''}`}>
                                    <div className="label"><label htmlFor="amount">Amount of money</label></div>
                                    <div className="money-box">
                                        <div className="input">
                                            <i>$</i>
                                            <input id="amount" type="number" onChange={e => actions.setCommitmentAmount(e.target.value)} defaultValue={data.commitmentAmount}/>
                                            <div className="help">
                                                <i className="iconfont icon-question"></i>
                                                <div className="dark-box">
                                                    <div className="text-c">
                                                        <h3>How much should I commit? </h3>
                                                        <p>Choose an amount that would be painful for you to lose. Your commitment needs to be motivating, and too low of an amount doesn’t work as well.</p>
                                                        <p>Think about it this way—how important is losing this weight to you? How much would you pay to guarantee that you’d lose this weight? This is a good place to start.</p>
                                                        <p>On the other side, don’t commit so much that it’ll put your well-being at risk. Once you start your commitment, you can’t take it back (that’s why this works).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`message ${data.commitmentAmountFeedback.length ? 'error' : ''}`}>
                                            <p>Minimum commitment: $100</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="prev-next">
                <a onClick={() => actions.setCurrentStep(2)} className="btn-c prev">Previous</a>
                <a onClick={actions.processCommitmentOne} className="btn-c next">Next</a>
            </div>
        </React.Fragment>
    );
};

export default CommitmentAmount;
