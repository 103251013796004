import React, {useContext} from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { OnboardingContext } from "../contexts";

import ProgressBar from "./Progressbar";
import OnboardingHeader from "./OnboardingHeader";
import AccountForm from "./AccountForm";
import GoalForm from "./GoalForm";
import CommitmentAmount from "./CommitmentAmount";
import CommitmentCauses from "./CommitmentCauses";
import CommitmentAnticharity from "./CommitmentAnticharity";
import PaymentForm from "./PaymentForm";
import FinalConfirmation from "./FinalConfirmation";
import LockedIn from "./LockedIn";
import SmartHelp from "./SmartHelp";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_FRONTEND_KEY);


const Onboarding = () => {
    const { data: {currentStep, commitStep} } = useContext(OnboardingContext);
    const stepClass = (currentStep === 3 && commitStep > 0) ? "step-4" : `step-${currentStep}`;
    const subStepClass = (currentStep === 4) ? 'step-4-3' : `step-4-${commitStep}`;
    const activeStepClass = (currentStep === 4 || (currentStep === 3 && commitStep >= 1)) ? subStepClass : '';
    const useWideContent = [5, 4].includes(currentStep) || (currentStep === 3 && commitStep >= 1);
    return (
        <React.Fragment>
            <OnboardingHeader />
            <div className={`step ${stepClass} ${activeStepClass}`}>
                <div className="left">
                    <SmartHelp currentStep={currentStep} commitStep={commitStep}   />
                </div>
                <div className="right">
                    <div className="content">
                        <ProgressBar
                            steps={["Info", "Goals", "Commitment", "Transfer method", "Confirmation"]}
                            currentStep={currentStep}
                        />
                        <div className={`width-content ${useWideContent ? "big" : "small"}`}>
                            <AccountForm />
                            <GoalForm />
                            <CommitmentAmount />
                            <CommitmentCauses />
                            <CommitmentAnticharity />
                            <Elements stripe={stripePromise}>
                                <PaymentForm />
                                <FinalConfirmation />
                            </Elements>
                            <LockedIn />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Onboarding;
