import React, { useContext, useState } from "react";
import {Elements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import { OnboardingContext } from "../contexts";

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

const PaymentForm = () => {
    const { data, actions } = useContext(OnboardingContext);
    const [showError, setShowError] = useState(false);
    const [cardComplete, setCardComplete] = useState({
      cardNumber: false, cardExpiry: false, cardCvc: false
    });
    const styles = (data.currentStep === 4) ? {} : {display: "none"};

    function handleCardElementOnChange(e) {
      setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
    }

    return (
        <React.Fragment>
            <div className="before-btns" style={styles}>
                <div className="form style2">
                    <form action="" method="post">
                        <div className="box">
                            <div className="item-c radio-c">
                                <div className="label">
                                    <label>
                                        Your commitment amount is ${data.commitmentAmount}. <br />
                                        How would you like to deposit it?
                                    </label>
                                </div>
                            </div>
                            <div className="group">
                                <div className="item-c input-c">
                                    <div className="label">
                                        <label htmlFor="number">Card Number</label>
                                    </div>
                                    <div className="input">
                                        {/* <i className="iconfont icon-card"></i> */}
                                        <CardNumberElement
                                            options={CARD_ELEMENT_OPTIONS}
                                            onChange={handleCardElementOnChange}
                                        />
                                    </div>
                                </div>
                                <div className="item-c input-c half">
                                    <div className="label">
                                        <label htmlFor="date">Expiry Date</label>
                                    </div>
                                    <div className="input">
                                        {/* <i className="iconfont icon-calendar"></i> */}
                                        <CardExpiryElement
                                            options={CARD_ELEMENT_OPTIONS}
                                            onChange={handleCardElementOnChange}
                                        />
                                    </div>
                                </div>
                                <div className="item-c input-c half">
                                    <div className="label">
                                        <label htmlFor="CVV">CVV</label>
                                    </div>
                                    <div className="input">
                                        {/* <i className="iconfont icon-lock"></i> */}
                                        <CardCvcElement
                                            options={CARD_ELEMENT_OPTIONS}
                                            onChange={handleCardElementOnChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tips">
                            <i className="iconfont icon-security"></i>
                            <p>
                                Commit uses industry-standard encryption to protect the confidentiality of your personal
                                information. We do not store your payment information. Outside of your commitment, we will
                                never charge you without your permission. We hold your commitment amount in a lockbox until
                                your commitment date.
                            </p>
                        </div>
                    </form>
                    {showError && (
                        <div className="payment-form-error" style={{ color: "#fa755a", fontSize: "14px" }}>
                            Please complete all payment information before proceding to the next step.
                        </div>
                    )}
                </div>
            </div>
            <div className="prev-next" style={styles}>
                <a onClick={() => actions.setCurrentStep(3)} className="btn-c prev">
                    Previous
                </a>
                <a
                    onClick={() => {
                        if (!(cardComplete.cardNumber && cardComplete.cardExpiry && cardComplete.cardCvc)) {
                            setShowError(true);
                            return false;
                        }
                        setShowError(false);
                        actions.setCurrentStep(5);
                    }}
                    className={`btn-c next ${
                        cardComplete.cardNumber && cardComplete.cardExpiry && cardComplete.cardCvc ? "" : "disabled"
                    }`}
                    disabled={!(cardComplete.cardNumber && cardComplete.cardExpiry && cardComplete.cardCvc)}
                >
                    Next
                </a>
            </div>
        </React.Fragment>
    );
};

export default PaymentForm;
