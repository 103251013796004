import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout"
import OnboardingLayout from "./layouts/OnboardingLayout"
import SuccessLayout from './layouts/SuccessLayout';
import DashboardLayout from './layouts/DashboardLayout';

// import Onboarding from "./components/Onboarding";
import ScrollToTop from "./components/ScrollToTop";
import CommitmentSummary from "./components/CommitmentSummary";
// import './App.css';
import './scss/style.scss';

function App(props) {

  return (
    <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/commitment">
            <CommitmentSummary />
          </Route>
          <Route path="/dashboard">
            <DashboardLayout />
          </Route>
          <Route path="/onboarding" render={(props) => <OnboardingLayout {...props} />} />
          <Route path="/success" render={(props) => <SuccessLayout {...props} />} />
          <Route path="/login" render={(props) => <LoginLayout {...props} /> } />
          <Route path="/">
            <MainLayout />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
