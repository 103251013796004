import React, { useEffect, useState } from 'react'

import { useLocalStorage, ApiHelper } from "../helpers";

export default function DashboardLayout() {
    const [commitment, setCommitment] = useState();
    // const [weighInId, setWeighInId] = useLocalStorage('weighInId', null);
    // const [weighInKeyword, setWeighInKeyword] = useLocalStorage('weighInKeyword', null);
    // const [s3UploadInfo, setS3UploadInfo] = useState(null);
    // const [weighInPhoto, setWeighInPhoto] = useState(null);
    const [currentUser, setCurrentUser] = useState();
    const [loggedIn, setLoggedIn] = useState(true);
    const [targetDate, setTargetDate] = useState();
    const [orgList, setOrgList] = useState();
    const [antiCharity, setAntiCharity] = useState();

    useEffect(() => {
        ApiHelper.
        getMe().
        then(user => {
            console.log(user);
            setCurrentUser(user);
        }).
        catch(error => {
            console.log(error);
            setLoggedIn(false);
        });
        if (!commitment) {
            ApiHelper.getMyMostRecentCommitment()
            .then((data) => {
                if (data.id) {
                    setCommitment(data);
                    setTargetDate(new Date(data.target_date));
                } else {
                    alert("You do not have a current commitment, reroute to create commitment page");
                }
            })
            .catch(error => {
                console.log(error);
                alert("Error loading your commitment");
            })
        }
        if (!orgList) {
            ApiHelper.getOrgs()
            .then((data) => {
                setOrgList(data);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, []);

    useEffect(() => {
        if (orgList && commitment) {
            const organization = orgList.find(x => x.id === commitment.organization_id);
            setAntiCharity(organization);
        }
    }, [commitment, orgList]);

    useEffect(() => {
        if (!loggedIn) {
            window.location.href="/login/login";
        }
    }, [loggedIn]);

    const getDisplayInterval = () => {
        const now = new Date();
        const weightLossMillisecs = targetDate.getTime() - now.getTime();
        const weightLossDays = Math.round(weightLossMillisecs / (1000 * 3600 * 24));
        const weightLossWeeks = Math.round(weightLossDays / 7);
        const weightLossMonths = Math.round(weightLossDays / 30);

        if (weightLossDays >= 70) {
            return `${weightLossMonths} months left`;
        }
        if (weightLossDays >= 28) {
            return `${weightLossWeeks} weeks left`;
        }
        return `${weightLossDays} ${weightLossDays > 1 ? 'days' : 'day'} left`;
    }
    return (
        <React.Fragment>
            <header className="header-dashboard">
                <div className="brand">
                    <h1>
                        <a href="index.html">
                            <span style={{display: "none"}}>Commit</span>
                            <img src="images/logo-3.svg" alt="Commit"/>
                        </a>
                    </h1>
                </div>
                <div className="links">
                    <ul>
                        <li className="active"><a href="#"><i className="iconfont icon-link-1"></i></a></li>
                        <li><a href="#"><i className="iconfont icon-link-2"></i></a></li>
                    </ul>
                    <ul>
                        <li><a href="#"><i className="iconfont icon-link-3"></i></a></li>
                    </ul>
                </div>
            </header>

            <div className="dashboard">
                <div className="content">
                    <div className="left">
                        <div className="top">
                            {currentUser &&
                                <div className="person text-center">
                                    <div className="img-c"><img src="images/photo.png" /></div>
                                    <div className="text-c">
                                        <p>Welcome back,</p>
                                        <h2>
                                            {`${currentUser.first_name} ${currentUser.last_name}`}
                                        </h2>
                                    </div>
                                </div>
                            }
                            <div className="links">
                                <ul>
                                    <li className="active"><a href="#"><i className="iconfont icon-link-1"></i>Dashboard</a></li>
                                    {/* <li><a href="#"><i className="iconfont icon-link-2"></i>My Account</a></li> */}
                                </ul>
                            </div>
                        </div>
                        {/* <ul>
                            <li><a href="#"><i className="iconfont icon-link-3"></i>Settings</a></li>
                        </ul> */}
                    </div>
                    <div className="right">
                        <div className="right-content">
                            <div className="group">
                                <div className="item-c">
                                    <h3>🎯 Weight loss goal</h3>
                                    {commitment && targetDate && (
                                        <p>
                                            {`${Math.round(commitment.start_weight - commitment.target_weight)} kilograms by ${targetDate.toLocaleDateString()} - ${getDisplayInterval()}`}
                                        </p>
                                    )}
                                    <a href="javascript:void(0);" className="btn-c turn-on-pop" id="turn-on-pop4"><i className="iconfont icon-share"></i>It’s the day!</a>
                                </div>
                                {commitment && antiCharity &&
                                    <div className="item-c">
                                        <h3>💵 What’s at stake</h3>
                                        <p>{`$${commitment.commitment_amount / 100} will go to ${antiCharity.name}`}</p>
                                        <a href="javascript:void(0);" className="btn-c turn-on-pop"><i className="iconfont icon-share"></i>Share my progress</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pop-up style4" id="pop-up4">
                <div className="bg-box turn-off-pop"></div>
                <div className="container">
                    <div className="content">
                        <div className="content-box text-center">
                            <div className="title-box">
                                <h2>It’s the day!</h2>
                                <p>Take a video and photo weighing yourself on a scale, with this keyword on a piece of paper.</p>
                                <i className="iconfont icon-close turn-off-pop"></i>
                            </div>
                            <div className="form">
                                <form action="">
                                    <div className="box">
                                        <div className="uploads">
                                            <div className="upload">
                                                <label for="photo">
                                                    <i className="iconfont icon-camera"></i>
                                                    <p><strong>Drag &amp; Drop</strong> <br />your files here, or <u>browse</u></p>
                                                </label>
                                                <input type="file" name="photo" id="photo" />
                                            </div>
                                            <div className="show-photo">
                                                <div className="img-c">
                                                    <i className="iconfont icon-close"></i>
                                                    <img src="" />
                                                </div>
                                            </div>
                                            <div className="upload">
                                                <label for="photo">
                                                    <i className="iconfont icon-video"></i>
                                                    <p><strong>Drag &amp; Drop</strong> <br />your files here, or <u>browse</u></p>
                                                </label>
                                                <input type="file" name="video" id="video" />
                                            </div>
                                            <div className="show-photo">
                                                <div className="img-c">
                                                    <i className="iconfont icon-close"></i>
                                                    <img src="" />
                                                </div>
                                            </div>
                                        </div>
                                        <p>Our referee will look at your weigh-in and give a final result.</p>
                                        <h2>How do you feel you did?</h2>
                                        <div className="textarea">
                                            <textarea rows="" cols="" placeholder="Write your comment..."></textarea>
                                            <div className="icons">
                                                <i className="iconfont icon-face"></i>
                                                <i className="iconfont icon-link"></i>
                                            </div>
                                        </div>
                                        <div className="submit"><input type="submit" name="submit" id="submit" value="Send" className="btn-c disabled" /></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pop-up style5">
                <div className="bg-box turn-off-pop"></div>
                <div className="container">
                    <div className="content">
                        <div className="title-box">
                            <h2>Share my progress</h2>
                            <i className="iconfont icon-close turn-off-pop"></i>
                        </div>
                        <div className="content-box">
                            <div className="grey-box">
                                <div className="top">
                                    <div className="left">
                                        <h3>My terms of commitment</h3>
                                        <p>12 kilograms by 12/12/2020</p>
                                    </div>
                                    <div className="right">My Current Outcome: - 2 kg</div>
                                </div>
                                <div className="images">
                                    <div className="img-c"><div style={{backgroundImage: "url('/images/img-8.jpg')"}}></div></div>
                                    <div className="img-c"><div style={{backgroundImage: "url('/images/img-9.jpg')"}}></div></div>
                                </div>
                            </div>
                            <div className="form style2">
                                <form action="" method="post">
                                    <div className="box">
                                        <div className="item-c check-c">
                                            <input type="checkbox" name="checkbox1" id="checkbox1" />
                                            <div className="label"><label for="checkbox1">Show my terms of commitment</label></div>
                                        </div>
                                        <div className="item-c check-c">
                                            <input type="checkbox" name="checkbox2" id="checkbox2" />
                                            <div className="label"><label for="checkbox2">Show my Current Outcome</label></div>
                                        </div>
                                        <div className="item-c check-c">
                                            <input type="checkbox" name="checkbox3" id="checkbox3" />
                                            <div className="label"><label for="checkbox3">Show my weigh-in videos</label></div>
                                        </div>
                                        <div className="item-c check-c">
                                            <input type="checkbox" name="checkbox4" id="checkbox4" />
                                            <div className="label"><label for="checkbox4">Show my photos</label></div>
                                        </div>
                                    </div>
                                </form>
                                <div className="prev-next">
                                    <a href="javascript:void(0);" className="btn-c prev turn-off-pop">Cancel</a>
                                    <a href="#" className="btn-c next"><i className="iconfont icon-share"></i>Share my progress</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
