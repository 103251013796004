import React, { useContext } from "react";

import { OnboardingContext } from "../contexts";
import NumberInput from "./NumberInput";
import showValidationClass from "../helpers/showValidationClass";

// import styles from "./Onboarding.module.scss";
const GoalFeedBack = () => {
    const { data, actions } = useContext(OnboardingContext);
    const weightLossInfo = actions.getWeightLossCalculation();
    if (!weightLossInfo || !weightLossInfo.target) {
        return <div className="message"> </div>;
    }
    const successClass = weightLossInfo.errorState ? "no" : "yes";
    let message = `You'll be losing ${weightLossInfo.target} ${weightLossInfo.longUnits}`;
    if (weightLossInfo.targetBmiTooLow) {
        message = "Final BMI below healthy range";
    } else if (weightLossInfo.weightGain) {
        message = "Positive weight gain";
    } else if (weightLossInfo.weightLossTooFast) {
        message = "Weight loss rate too fast - we suggest no more than 2 pounds a week.";
    }
    return (
        <div className={`message ${successClass}`}>
            <i className={`iconfont icon-${successClass}`}></i>
            <p>{message}</p>
        </div>
    );
};
const GoalForm = () => {
    const { data, actions } = useContext(OnboardingContext);
    const weightLossInfo = actions.getWeightLossCalculation();
    console.log(weightLossInfo);

    if (data.currentStep !== 2) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="before-btns">
                <div className="text-c text-center">
                    <h2>Set your weight loss goal</h2>
                    <p>
                        Losing 1-2 pounds per week is usually the <br />
                        right balance of healthy and ambitious
                    </p>
                </div>
                <div className="form style2">
                    <form action="" method="post">
                        <div className="box">
                            <div className="item-c input-c with-bool with-check number-c">
                                <div className="label">
                                    <label htmlFor="dweight">Desired weight</label>
                                </div>
                                <div className="bool-box">
                                    <div className={`input ${weightLossInfo.errorState ? 'error' : ''} ${showValidationClass("targetWeight", data.goalFormFeedback)}`}>
                                        <NumberInput
                                            id="dweight"
                                            onChange={(e) => actions.setTargetWeight(e)}
                                            defaultValue={data.targetWeight}
                                        />
                                    </div>
                                    <div>{` ${data.weightDisplayUnits}`}</div>
                                </div>
                                <GoalFeedBack />
                            </div>
                            <div className={`item-c date-c ${showValidationClass("targetDate", data.goalFormFeedback)}`}>
                                <div className="label">
                                    <label>By this date</label>
                                </div>
                                <div className="input">
                                    <input
                                        type="date"
                                        onChange={(e) => actions.setTargetDate(e.target.value)}
                                        value={data.targetDate}
                                    />
                                    <i className="iconfont icon-calendar"></i>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="prev-next">
                <a onClick={(e) => actions.setCurrentStep(1)} className="btn-c prev">
                    Previous
                </a>
                <a onClick={() => {
                        if (weightLossInfo.errorState) {
                            return;
                        }
                        actions.processGoalForm();
                    }}
                    className="btn-c next">
                    Next
                </a>
            </div>
            {data.goalFormFeedback && (
                <div className="onboarding-error-feedback">
                    <ul>
                        {data.goalFormFeedback.map((feedback) => {
                            return <li>{feedback.message}</li>;
                        })}
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default GoalForm;
