import React from 'react'

export default function HowItWorks() {
    return (
        <React.Fragment>
            <div class="guide inner-banner">
                <div class="container-fluid">
                    <div class="content text-center bg-grey">
                        <div class="text-c">
                            <p class="subtitle">advantages of using</p>
                            <h2>How It Works</h2>
                            <p>It’s simple: make a bet that you’ll lose weight. If you win, you get your money back. If you fail, all your money goes to an organization you hate.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="timeline">
                <div class="container">
                    <div class="content">
                        <div class="item-c">
                            <div class="text-c">
                                <i class="iconfont icon-icon-1"></i>
                                <h3>Set your weight loss goal</h3>
                                <p>Set your weight loss goal (___ pounds by ___)</p>
                            </div>
                            <div class="img-c"><img src="images/img-2.png" /></div>
                        </div>
                        <div class="item-c">
                            <div class="text-c">
                                <i class="iconfont icon-icon-2"></i>
                                <h3>Choose your <br />commitment amount</h3>
                                <p>This should be an amount that is really <br />motivating to you. </p>
                            </div>
                            <div class="img-c"><img src="images/img-3.png" /></div>
                        </div>
                        <div class="item-c">
                            <div class="text-c">
                                <i class="iconfont icon-icon-3"></i>
                                <h3>Choose your <br />commitment nemesis</h3>
                                <p>This is an organization that is the complete opposite of <br />everything you stand for. You do NOT want to support <br />this organization, at any cost.</p>
                            </div>
                            <div class="img-c"><img src="images/img-4.png" /></div>
                        </div>
                        <div class="item-c">
                            <div class="text-c">
                                <i class="iconfont icon-icon-4"></i>
                                <h3>We hold your <br />commitment in a lockbox.</h3>
                                <p>Your money's held in a separate account <br />until your commitment ends.</p>
                            </div>
                            <div class="img-c"><img src="images/img-5.png" /></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="end-weight">
                <div class="container-fluid">
                    <div class="content">
                        <div class="title-box">
                            <div class="img-c"><img src="images/line-1.png" /></div>
                            <h2>Weigh in at the end of your commitment</h2>
                            <div class="img-c"><img src="images/line-2.png" /></div>
                        </div>
                        <div class="group">
                            <div class="item-c win">
                                <div class="text-c">
                                    <div class="box">
                                        <h3>If you succeed,</h3>
                                        <p>we return your funds (awarding ourselves a success fee).</p>
                                    </div>
                                    <img src="images/icon-checked.svg" alt=""/>
                                </div>
                                <div class="img-c"><img src="images/weight-1.png" /></div>
                            </div>
                            <div class="item-c fail">
                                <div class="text-c">
                                    <div class="box">
                                        <h3>If you lose,</h3>
                                        <p>your nemesis gets your hard-earned dollars.</p>
                                    </div>
                                    <img src="images/icon-cancel.svg" alt=""/>
                                </div>
                                <div class="img-c"><img src="images/weight-2.png" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="subscribe">
                <div class="container-fluid">
                    <div class="content text-center">
                        <div class="text-c">
                            <h2>Your personal assistant in achieving your goals</h2>
                            <div class="form style1">
                                <p>Get notified when we launch</p>
                                <form action="/">
                                    <div class="box">
                                        <div class="input"><input type="email" name="email" id="s-email" placeholder="Email Address" /></div>
                                        <div class="submit"><input type="submit" name="submit" id="s-submit" value="Subscribe" class="btn-c" /></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
