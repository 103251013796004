export const categorizedQuestions = [
    {
        categoryName: "Other goals",
        iconClassName: "icon-icon-5",
        id: 1,
        questions: [
            {
                question: "Is Commit compatible with my weight loss or fitness plan?",
                id: 1,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
        ],
    },
    {
        categoryName: "Pricing & Plans",
        iconClassName: "icon-icon-8",
        id: 2,
        questions: [
            {
                question: "How much does it cost?",
                id: 2,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "How much should I commit?",
                id: 3,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "How do you make money?",
                id: 4,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "If I lose my commitment, can I get a tax deduction on my donation?",
                id: 5,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "What payment methods do you support?",
                id: 6,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "Why take a fee when I succeed and not when I fail?",
                id: 7,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
        ],
    },
    {
        categoryName: "Commit Specification",
        iconClassName: "icon-icon-7",
        id: 3,
        questions: [
            {
                question: "Is Commit compatible with my weight loss or fitness plan?",
                id: 8,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "Can someone cheat to get out of their commitment?",
                id: 9,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
            {
                question: "How do I choose my Nemisis?",
                id: 10,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
        ],
    },
    {
        categoryName: "Verification",
        iconClassName: "icon-icon-8",
        id: 4,
        questions: [
            {
                question: "Is Commit compatible with my weight loss or fitness plan?",
                id: 11,
                response: [
                    "For this to work, you need to commit an amount that would be painful for you to lose. For some people, this means $100, and for others it might mean $10,000 or more.",
                    "At the same time, don't commit so much that it puts your well-being seriously at risk. Commit should be motivating, but your weight loss goals shouldn't come at an extreme cost.",
                    "We require a minimum commitment of $100.",
                ],
            },
        ],
    },
];

export default categorizedQuestions;
