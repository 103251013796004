import React from 'react'

export default function Homepage() {
    return (
        <React.Fragment>
            <div className="home-banner">
                <div className="container-fluid">
                    <div className="content bg-grey">
                        <div className="text-c">
                            <h2>Make a commitment to lose weight. For good.</h2>
                            <p>Commit weaponizes your psychology to crush your weight loss goals. You make a bet that you'll lose weight—or else it goes to someone you hate.</p>
                            <p><strong>This is not for everyone.</strong> It's only for hardcore people who are sick and tired of being fat and want to hit their goals at any cost.</p>
                            <div className="form style1">
                                <form action="/">
                                    <div className="box">
                                        <div className="input"><input type="email" name="email" id="email" placeholder="Your Email" /></div>
                                        <div className="submit"><input type="submit" name="submit" id="submit" value="Sign Up for Early Access" className="btn-c" /></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="img-c"><div style={{backgroundImage: "url('/images/img-1.png')"}}></div></div>
                    </div>
                </div>
            </div>
            
            <div className="guide">
                <div className="container-fluid">
                    <div className="content text-center">
                        <div className="text-c">
                            <p className="subtitle">your pathway</p>
                            <h2>How It Works</h2>
                            <p>It’s simple: make a bet that you’ll lose weight. If you win, you get your money back. If you fail, all your money goes to an organization you hate.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="timeline">
                <div className="container">
                    <div className="content">
                        <div className="item-c">
                            <div className="text-c">
                                <i className="iconfont icon-icon-1"></i>
                                <h3>Set your weight loss goal</h3>
                                <p>Set your weight loss goal (___ pounds by ___)</p>
                            </div>
                            <div className="img-c"><img src="/images/img-2.png" /></div>
                        </div>
                        <div className="item-c">
                            <div className="text-c">
                                <i className="iconfont icon-icon-2"></i>
                                <h3>Choose your <br />commitment amount</h3>
                                <p>This should be an amount that is really <br />motivating to you. </p>
                            </div>
                            <div className="img-c"><img src="/images/img-3-2.png" /></div>
                        </div>
                        <div className="item-c">
                            <div className="text-c">
                                <i className="iconfont icon-icon-3"></i>
                                <h3>Choose your <br />commitment nemesis</h3>
                                <p>This is an organization that is the complete opposite of <br />everything you stand for. You do NOT want to support <br />this organization, at any cost.</p>
                            </div>
                            <div className="img-c"><img src="/images/img-4.png" /></div>
                        </div>
                        <div className="item-c">
                            <div className="text-c">
                                <i className="iconfont icon-icon-4"></i>
                                <h3>We hold your <br />commitment in a lockbox.</h3>
                                <p>Your money's held in a separate account <br />until your commitment ends.</p>
                            </div>
                            <div className="img-c"><img src="/images/img-5.png" /></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="end-weight">
                <div className="container-fluid">
                    <div className="content">
                        <div className="title-box">
                            <div className="img-c"><img src="/images/line-1.png" /></div>
                            <h2>Weigh in at the end of your commitment</h2>
                            <div className="img-c"><img src="/images/line-2.png" /></div>
                        </div>
                        <div className="group">
                            <div className="item-c win">
                                <div className="text-c">
                                    <div className="box">
                                        <h3>If you succeed,</h3>
                                        <p>we return your funds (awarding ourselves a success fee).</p>
                                    </div>
                                    <img src="/images/icon-checked.svg" alt=""/>
                                </div>
                                <div className="img-c"><img src="/images/weight-1.png" /></div>
                            </div>
                            <div className="item-c fail">
                                <div className="text-c">
                                    <div className="box">
                                        <h3>If you lose,</h3>
                                        <p>your nemesis gets your <br />hard-earned dollars.</p>
                                    </div>
                                    <img src="/images/icon-cancel.svg" alt=""/>
                                </div>
                                <div className="img-c"><img src="/images/weight-2.png"  /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="subscribe">
                <div className="container-fluid">
                    <div className="content text-center">
                        <div className="text-c">
                            <h2>Your personal assistant in achieving your goals</h2>
                            <div className="form style1">
                                <p>Get notified when we launch</p>
                                <form action="/">
                                    <div className="box">
                                        <div className="input"><input type="email" name="email" id="s-email" placeholder="Email Address" /></div>
                                        <div className="submit"><input type="submit" name="submit" id="s-submit" value="Subscribe" className="btn-c" /></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="features">
                <div className="container">
                    <div className="content">
                        <p className="subtitle">Features</p>
                        <div className="text-c">
                            <h2>Why Commit Works</h2>
                            <p>We don’t want to nerd out for too long about why this works, but Commit is based on Nobel-prize winning research in psychology and economics.</p>
                        </div>
                        <div className="group">
                            <div className="item-c">
                                <div className="img-c"><img src="/images/icon-1.svg"  /></div>
                                <div className="text-c">
                                    <h3>Loss Aversion</h3>
                                    <p>Losing $100 is more painful than winning $100. This is why losing your wallet causes a stronger reaction than finding money in your laundry</p>
                                </div>
                            </div>
                            <div className="item-c">
                                <div className="img-c"><img src="/images/icon-2.svg"  /></div>
                                <div className="text-c">
                                    <h3>Hot and Cold Cognition</h3>
                                    <p>You have two brains: a smart human brain, and an emotional lizard brain. When you’re in smart thinking mode, you want to make decisions that will keep your emotional brain in check. Commitments help you do that</p>
                                </div>
                            </div>
                            <div className="item-c">
                                <div className="img-c"><img src="/images/icon-3.svg"  /></div>
                                <div className="text-c">
                                    <h3>Social Pressure</h3>
                                    <p>The stakes get higher if you make your commitment public. Not only will you hate that your money went to your nemesis — but all your friends will know too</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="comments">
                <div className="container-fluid">
                    <div className="content bg-grey">
                        <div className="slider">
                            <div className="slick-c">
                                <div className="img-c"><div style={{backgroundImage: "url(/images/img-6.jpg)"}}></div></div>
                                <div className="text-c">
                                    <blockquote>“It’s unreal how well this works. When I get a craving or feel lazy, I just think about donating to [REDACTED NEMESIS] and I get fired up for another day.🔥🔥”</blockquote>
                                    <div className="cite">
                                        <div className="img-c"><div style={{backgroundImage: "url(/images/cite.png)"}}></div></div>
                                        <cite>
                                            <p className="name">Cameron Williamson</p>
                                            <p className="years">25 years, Toronto</p>
                                        </cite>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-c">
                                <div className="img-c"><div style={{backgroundImage: "url(/images/img-6.jpg)"}}></div></div>
                                <div className="text-c">
                                    <blockquote>“It’s unreal how well this works. When I get a craving or feel lazy, I just think about donating to [REDACTED NEMESIS] and I get fired up for another day.🔥🔥”</blockquote>
                                    <div className="cite">
                                        <div className="img-c"><div style={{backgroundImage: "url(/images/cite.png)"}}></div></div>
                                        <cite>
                                            <p className="name">Cameron Williamson</p>
                                            <p className="years">25 years, Toronto</p>
                                        </cite>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
