import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { OnboardingContext } from "../contexts";

// import styles from "./Onboarding.module.scss";

const LockedIn = () => {
    const { data, actions } = useContext(OnboardingContext);
    const history = useHistory();

    const styles = (data.currentStep === 6) ? {} : {display: "none"};
    
    return (
        <div style={styles}>
            <div>
                <h3>Commitment Locked In!</h3>
                <button onClick={e => {
                        console.log(e);
                        history.push("/weigh-in");
                    }}>Let's Start</button>
            </div>
        </div>
    );
};

export default LockedIn;
