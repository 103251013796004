import React, { useContext, useState, useEffect } from "react";
import useDimensions from "react-use-dimensions";

import { OnboardingContext } from "../contexts";
import CountrySelect from "./CountrySelect";
import NumberInput from "./NumberInput";
import showValidationClass from "../helpers/showValidationClass";

export function BooleanInput({
    values,
    onChange=(selected)=>{console.log(selected)},
    initialValue=null,
}) {
    const [activeItem, setActiveItem] = useState(0);
    const [optionOneRef, optionOneSize] = useDimensions();
    const [optionTwoRef, optionTwoSize] = useDimensions();
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (activeItem === 0) {
            setLeft(0);
            setWidth(optionOneSize.width);
        } else {
            setLeft(optionOneSize.width);
            setWidth(optionTwoSize.width);
        }
        onChange(values[activeItem].value);
    });

    useEffect(() => {
        const starter = (initialValue === values[1].value) ? 1 : 0;
        setActiveItem(starter);
    }, [])

    return (
        <div className={`bool ${activeItem===0 ? '':'active'}`} onClick={() => setActiveItem((activeItem + 1) % 2)}>
            <span ref={optionOneRef}>
                {values[0].label}
            </span>
            <span ref={optionTwoRef}>
                {values[1].label}
            </span>
            <span className="bg" style={{left, width}}></span>
        </div>
    )
}

const AccountForm = () => {
    const { data, actions } = useContext(OnboardingContext);
    if (data.currentStep !== 1) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="before-btns">
                <div className="text-c text-center">
                    <h2>
                        Welcome! <br />
                        Let's start with basic info:
                    </h2>
                </div>
                <div className="form style2">
                    <form action="" method="post">
                        <div className="box">
                            <div className="two-col">
                                <div
                                    className={`item-c input-c ${showValidationClass("country", data.accountFormFeedback)}`}
                                >
                                    <div className="label">
                                        <label htmlFor="country">Country</label>
                                    </div>
                                    <div className="input">
                                        <CountrySelect
                                            defaultValue={data.country}
                                            onChange={(e) => actions.setCountry(e)}
                                            selected={data.country}
                                            id="country"
                                        />
                                    </div>
                                </div>
                                <div className={`item-c input-c number-c ${showValidationClass("age", data.accountFormFeedback)}`}>
                                    <div className="label">
                                        <label htmlFor="age-input">Age</label>
                                    </div>
                                    <div className="input">
                                        <NumberInput id="age-input" onChange={e => actions.setAge(e)} defaultValue={data.age} />
                                    </div>
                                </div>
                            </div>
                            <div className={`item-c input-c with-bool number-c ${showValidationClass("height", data.accountFormFeedback)}`}>
                                <div className="label">
                                    <label htmlFor="height">Height</label>
                                </div>
                                <div className="bool-box">
                                    <div className="input">
                                        <NumberInput id="height-input" onChange={e => actions.setHeight(e)} defaultValue={data.height} />
                                    </div>
                                    <BooleanInput
                                        values={[
                                            { label: "cm", value: "cm" },
                                            { label: "inches", value: "inches" },
                                        ]}
                                        initialValue={data.heightDisplayUnits}
                                        onChange={(unit) => actions.setHeightDisplayUnits(unit)}
                                    />
                                </div>
                            </div>
                            <div className={`item-c input-c with-bool number-c ${showValidationClass("weight", data.accountFormFeedback)}`} >
                                <div className="label">
                                    <label htmlFor="weight">Weight</label>
                                </div>
                                <div className="bool-box">
                                    <div className="input">
                                        <NumberInput id="weight-input" onChange={e => actions.setWeight(e)} defaultValue={data.weight} />
                                    </div>
                                    <BooleanInput
                                        values={[
                                            { label: "kg", value: "kg" },
                                            { label: "lbs", value: "lb" },
                                        ]}
                                        initialValue={data.weightDisplayUnits}
                                        onChange={(unit) => actions.setWeightDisplayUnits(unit)}
                                    />
                                </div>
                            </div>
                            <div className="item-c radio-c">
                                <div className="label">
                                    <label>Sex</label>
                                </div>
                                <div className="single-radio">
                                    <div className="choose">
                                        <input
                                            type="radio"
                                            name="sex"
                                            id="sex-male"
                                            value="male"
                                            checked={data.sex === "male"}
                                            onChange={(e) => actions.setSex(e.target.value)}
                                        />
                                        <label htmlFor="sex-male">Male</label>
                                    </div>
                                    <div className="choose">
                                        <input
                                            type="radio"
                                            name="sex"
                                            id="sex-female"
                                            value="female"
                                            checked={data.sex === "female"}
                                            onChange={(e) => actions.setSex(e.target.value)}
                                        />
                                        <label htmlFor="sex-female">Female</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="prev-next">
                <a onClick={() => actions.processAccountForm()} className="btn-c">
                    Next
                </a>
            </div>
            {data.accountFormFeedback && (
                <div className="onboarding-error-feedback">
                    <ul>
                        {data.accountFormFeedback.map((feedback) => {
                            return <li>{feedback.message}</li>;
                        })}
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default AccountForm;
