import React from 'react'
import {
  Switch,
  Route,
  Link,
} from "react-router-dom";

export default function SuccessPage() {
    return (
        <div class="success-box">
            <div class="img-c"><img src="/images/bg-1.svg" /></div>
            <div class="text-c text-center">
                <i>🎉</i>
                <h2>Commitment Locked In!</h2>
                <p class="lead-body">Great job on taking this serious step on your weight loss journey.</p>
                <p>We would congratulate you more on making the commitment, but research shows that early celebration is bad for motivation. This is just the beginning. Our job is to support you the entire way. Here we go!</p>
                <Link to="/success/weigh-in" class="btn-c">Let’s start</Link>
            </div>
        </div>
    )
}
