import React from "react";

export const HelpInfo = () => {
    return (
        <div className="content">
            <div className="white-box">
                <div className="text-c">
                    <h3>Hello! I'm your assistant 👋🏻</h3>
                    <p>I'll guide you through setting up your commitment. </p>
                    <p>
                        Let's start with basic info on where you are today. This will help us track your weight loss and
                        figure out your energy needs
                    </p>
                </div>
            </div>
            <div className="logo">
                <div className="img-c">
                    <div style={{ backgroundImage: "url('/images/logo-4.svg')" }}></div>
                </div>
            </div>
        </div>
    );
};

export const HelpTransfer = () => {
    return (
        <React.Fragment>
            <div className="content">
                <div className="logo">
                    <div className="img-c">
                        <div style={{ backgroundImage: "url('/images/logo-4.svg')" }}></div>
                    </div>
                </div>
                <div className="white-box">
                    <div className="text-c">
                        <h3>We’re near the end here 👍🏻</h3>
                        <p>To fund your commitment, we'll need a payment method.</p>
                        <p>We hold your commitment in a lockbox, and we don't touch it until your commitment ends.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export const HelpConfirmation = () => {
    return (
        <div className="content">
            <div className="logo">
                <div className="img-c">
                    <div style={{ backgroundImage: "url('/images/logo-4.svg')" }}></div>
                </div>
            </div>
            <div className="white-box">
                <div className="text-c">
                    <h3>Here’s the final step!</h3>
                    <p>
                        Make sure you’re ready—after you commit, we’re not going to let you take it back, because otherwise
                        this wouldn’t work. We ❤️ you and want you to succeed.
                    </p>
                </div>
            </div>
        </div>
    );
};

export const HelpGoals = () => {
    return (
        <React.Fragment>
            <div className="bg-box">
                <div className="img-c">
                    <img src="/images/step-1.png" />
                </div>
            </div>
            <div className="content">
                <div className="logo">
                    <div className="img-c">
                        <div style={{ backgroundImage: "url('/images/logo-4.svg')" }}></div>
                    </div>
                </div>
                <div className="white-box">
                    <div className="text-c">
                        <h3>Next, set a realistic weight loss goal 💪🏻</h3>
                        <p>Choose how much you want to weigh by when.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export const HelpCommitment = ({ commitStep }) => {
    return (
        <React.Fragment>
            {0 === commitStep && (
                <div className="bg-box width-full">
                    <div className="img-c">
                        <img src="/images/step-2.png" />
                    </div>
                </div>
            )}
            {1 === commitStep && (
                <div className="bg-box">
                    <div className="img-c">
                        <img src="/images/step-4.png" />
                    </div>
                </div>
            )}
            <div className="content">
                <div className="logo">
                    <div className="img-c">
                        <div style={{ backgroundImage: "url('/images/logo-4.svg')" }}></div>
                    </div>
                </div>
                <div className="white-box">
                    {![0, 2].includes(commitStep) && (
                        <div className="text-c">
                            <h3>Now we’ll help you find your commitment nemesis 👻</h3>
                            <p>To start, choose things that you LIKE or that make you feel happy</p>
                        </div>
                    )}
                    {0 === commitStep && (
                        <div className="text-c">
                            <h3>This next part is what makes Commit special ✨</h3>
                            <p>
                                Some see their commitment as a bet on themselves. Others see it as a punishment they want to
                                avoid at all costs. Either way, it’s super motivating. And it works.
                            </p>
                        </div>
                    )}
                    {2 === commitStep && (
                        <div className="text-c">
                            <h3>Your commitment nemesis 😈</h3>
                            <p>The one organization you hate most will be your commitment nemesis.</p>
                            <p>
                                If you fail your commitment, all your money goes to them. So not only are you losing money,
                                you're donating to a cause you despise
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export const SmartHelp = ({ currentStep, commitStep }) => {
    switch (currentStep) {
        case 1:
            return <HelpInfo />;
        case 2:
            return <HelpGoals />;
        case 3:
            return <HelpCommitment commitStep={commitStep} />;
        case 4:
            return <HelpTransfer />;
        case 5:
            return <HelpConfirmation />;
        default:
            return <HelpInfo />;
    }
};

export default SmartHelp;
