import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";

import { ApiHelper } from "../helpers";

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const loginUser = () => {
        setError(null);
        ApiHelper.login(email, password).then(() => {
            window.location.href = "/dashboard";
        }).catch(() => {
            setError("There was a problem logging in, please heck your email and password and try again.");
        })
    }
    useEffect(() => {
        async function checkUserLogin() {
            const userStatus = await ApiHelper.isLoggedIn();
            if (userStatus) {
                window.location.href = "/dashboard";
            }
        }
        checkUserLogin();
    }, []);

    return (
        <React.Fragment>
            <div className="right-fixed">
                <p>No account? <Link to="/login/signup">Sign Up</Link></p>
            </div>
            <div className="content">
                <div className="text-c">
                    <h2>Log In to Commit</h2>
                </div>
                {/* <div className="login-social">
                    <ul>
                        <li className="google"><a href="dashboard.html"><i className="iconfont icon-google"></i><span>Sign Up with Google</span></a></li>
                        <li><a href="dashboard.html"><i className="iconfont icon-facebook" title="Login with Facebook"></i></a></li>
                        <li><a href="dashboard.html"><i className="iconfont icon-twitter" title="Login with Twitter"></i></a></li>
                    </ul>
                </div>
                <div className="or">
                    <p>Or</p>
                </div> */}
                {error &&
                    <div className="error">{error}</div>
                }
                <div className="form style2">
                    <form onSubmit={(e) => {e.preventDefault();loginUser();}}>
                        <div className="box">
                            <div className="item-c input-c">
                                <div className="label"><label htmlFor="email">Email</label></div>
                                <div className="input"><input type="email" name="email" id="email" placeholder="Enter your Email" onChange={e => setEmail(e.target.value)}/></div>
                            </div>
                            <div className="item-c input-c">
                                <div className="label"><label htmlFor="password">Password</label><Link to="/login/forgot">Forgot Password?</Link></div>
                                <div className="input"><input type="password" name="password" id="password" onChange={e => setPassword(e.target.value)}/></div>
                            </div>
                            <div className="item-c check-c">
                                <input type="checkbox" name="checkbox" id="checkbox" />
                                <div className="label"><label htmlFor="checkbox">Keep me logged in</label></div>
                            </div>
                            <div className="submit"><input type="submit" name="submit" id="submit" value="Log in" className="btn-c" /></div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
