import React from "react";

export default function StoryPage() {
    return (
        <React.Fragment>
            <div className="guide inner-banner small-height">
                <div className="container-fluid">
                    <div className="content text-center bg-grey">
                        <div className="text-c">
                            <h2>Founder’s Story</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single-editor">
                <div className="container-fluid">
                    <div className="content">
                        <div className="text-c">
                            <h2>Hi, I’m Allen! 👋🏻</h2>
                            <p className="lead-body">
                                I’m a startup founder and I struggled with weight for most of my life.{" "}
                            </p>
                            <p>
                                I was a chubby kid all the way through high school. My parents didn’t really understand
                                things like calories and portion control, and despite their best efforts I stayed chubby. We
                                tried all sorts of odd diets, supplements, and ancient mythical Chinese medicines. Nothing
                                really worked.
                            </p>
                            <div className="images">
                                <img src="/images/single-2.jpg" style={{ width: "48.5%" }} />
                                <img src="/images/single-1.jpg" style={{ width: "48.5%" }} />
                            </div>
                            <p>
                                I was teased at school, picked last for the basketball team, and all sorts of cliche chubby
                                kid stuff.
                            </p>
                            <p>
                                In college I had my first real success with weight loss, getting into the normal weight
                                range, but it didn’t stick. I yo-yo’d up and down by 20 pounds every year. I’d lose weight
                                quickly in intense sprints, then I’d balloon back up when stress and work got in the way. I
                                never really changed my eating and lifestyle habits.
                            </p>
                            <p>
                                In March 2015, I hit 180 pounds. At 5’10” in height, I was at an all-time high in weight. I
                                was in the middle of starting my first company (PrepScholar) and had all the work stress and
                                bad eating habits that came with it. I felt like I had no real control over my weight, and it
                                sucked.
                            </p>
                            <p>
                                I decided enough was enough, and I wanted to lose weight for good, permanently this time.
                                Here’s where I started off:
                            </p>
                            <div className="images">
                                <img src="/images/single-3.jpg" style={{ width: "68.5%" }} />
                                <img src="/images/single-4.jpg" style={{ width: "29%" }} />
                            </div>
                            <h3>Weight Loss is Frickin’ Hard</h3>
                            <p>
                                I’d tried to lose weight over a dozen times in the past, so I already knew the basics—eat
                                fewer calories than you burn each day, and you’ll lose weight. It’s simple, but simple
                                doesn’t mean easy. Losing weight is painful - you’re hungry all the time, you crave foods you
                                can’t have, you feel weak and tired.{" "}
                            </p>
                            <p>
                                For everyone trying to lose weight, your biology works against you. Our caveman ancestors
                                lived in environments where food was scarce. As humans, we evolved to hoard as many calories
                                as we could. It’s no wonder that eating food feels so easy, and depriving yourself of food
                                feels terrible.
                            </p>
                            <p>
                                That deep-rooted biology, honed over hundreds of millions of years of evolution, works
                                completely against you. Anyone who’s struggled to lose weight feels this.
                            </p>
                            <ul>
                                <li>
                                    The cravings you get are unreal. You go out to dinner with friends and salivate looking
                                    at everyone else’s food. You can’t watch commercials for cheeseburgers because you start
                                    demanding one.
                                </li>
                                <li>
                                    You promise yourself you’ll have just one cheat meal per week. It’s delicious, and you
                                    overeat. The next day your friend invites you to lunch, so you think, “ok, I’ll just have
                                    another semi-cheat meal and reset next week.” Before you know it you’re off your diet
                                    entirely.
                                </li>
                                <li>
                                    The scale comes in fits and starts. You’ll lose 3 pounds in one day and feel glorified,
                                    then you’ll be stuck at the same weight for a week. It’s demoralizing, you think all the
                                    pain is pointless, and you drown your sorrows in ice cream.
                                </li>
                            </ul>
                            <h3>Going to the Extreme</h3>
                            <p>Knowing how hard it was, I wanted a commitment device to keep me on track. </p>
                            <p>
                                In a previous weight loss sprint, I’d made a bet with a friend—I need to lose x pounds by
                                this date, or I’ll give you $200. This was on the right track, but it failed for a key
                                reason—I didn’t really mind that my friend got my money. He’d use it to pay rent or buy
                                something he liked, maybe even treat me to dinner. The pain of losing the money to my friend
                                wasn’t enough.
                            </p>
                            <p>
                                This time, I came up with a new idea:{" "}
                                <strong>instead of giving money to my friend, the bet would BURN money.</strong> It would be
                                a pure, total loss.
                            </p>
                            <p>
                                Here’s how it worked: if I didn’t lose 30 pounds and hit 148 lbs by November, here was the
                                punishment:
                            </p>
                            <ul>
                                <li>
                                    I would fly round trip from Boston to San Francisco (where my friend lived). Except I
                                    would have no layover - I’d get off the plane in SF, then jump on another plane right
                                    back to Boston. This would be about 14 hours total of flight time and transit in a single
                                    day.
                                </li>
                                <li>
                                    I’d have to book a middle seat for the most uncomfortable flight position.
                                    <ol>
                                        <li>I’d have to book a middle seat for the most uncomfortable flight position.</li>
                                        <li>And the kicker: I was not allowed to bring my laptop or phone with me, </li>
                                    </ol>
                                </li>
                                <li>
                                    And the kicker: I was not allowed to bring my laptop or phone with me, or to use
                                    in-flight entertainment. I’d have to sit on the plane doing nothing.
                                    <ul>
                                        <li>I’d have to book a middle seat for the most uncomfortable flight position.</li>
                                        <li>And the kicker: I was not allowed to bring my laptop or phone with me, </li>
                                    </ul>
                                </li>
                            </ul>
                            <ol>
                                <li>
                                    I would fly round trip from Boston to San Francisco (where my friend lived). Except I
                                    would have no layover - I’d get off the plane in SF, then jump on another plane right
                                    back to Boston. This would be about 14 hours total of flight time and transit in a single
                                    day.
                                </li>
                                <li>
                                    I’d have to book a middle seat for the most uncomfortable flight position.
                                    <ol>
                                        <li>I’d have to book a middle seat for the most uncomfortable flight position.</li>
                                        <li>And the kicker: I was not allowed to bring my laptop or phone with me, </li>
                                    </ol>
                                </li>
                                <li>
                                    And the kicker: I was not allowed to bring my laptop or phone with me, or to use
                                    in-flight entertainment. I’d have to sit on the plane doing nothing.
                                    <ul>
                                        <li>I’d have to book a middle seat for the most uncomfortable flight position.</li>
                                        <li>And the kicker: I was not allowed to bring my laptop or phone with me, </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                For someone obsessed with productivity like me, this would be intolerable. Not only would I
                                pay $600 for the ticket and endure 14 hours of uncomfortable travel, I’d waste an entire day.
                            </p>
                            <p>I’d do anything to avoid that punishment.</p>
                            <p>And it worked. In November 2015, right at the deadline, I hit my mark of 148 pounds:</p>
                            <div className="images">
                                <img src="/images/single-5.jpg" />
                            </div>
                            <p>
                                This was the least I’d weighed since I was probably 12 years old (albeit chubbier and
                                shorter).
                            </p>
                            <p>I was certainly the leanest I’d ever been:</p>
                            <div className="images">
                                <img src="/images/single-6.jpg" />
                            </div>
                            <p>
                                I was really happy with myself, and this was already the most successful weight loss period
                                I’d had.
                            </p>
                            <p>
                                But I knew that I had a tendency to yo-yo, and I wanted to avoid that at all costs. Losing 30
                                pounds over 8 months was painful; I didn’t want to have to go through that again. So I set up
                                a second commitment with my friend—maintain the same weight for the next 3 months.
                            </p>
                            <p>
                                And again, the commitment worked. Where in the past I would’ve fallen off the wagon, this
                                time I kept on the straight and narrow. And each day got easier—it was like my body
                                readjusted to the new normal. I craved less food, I got full more easily, and I didn’t like
                                overeating.
                            </p>
                            <p>
                                I’ve managed to keep the weight off for 5 years. I’ve stayed about the same weight, but I
                                started exercising more and got fitter. Here’s a photo from this year:
                            </p>
                            <div className="images">
                                <img src="/images/single-7.jpg" />
                            </div>
                            <p>
                                So for me, the commitment was the key that made everything work. Even when I felt endless
                                temptations to go off my weight loss plan, the threat of facing a steep punishment was even
                                stronger. It kept me going through my weakest points and all the way to the end.
                            </p>
                            <h3>Commit is for You</h3>
                            <p>
                                Commit takes these principles to help anyone lose weight and keep it off, like never before.
                            </p>
                            <p>
                                Commit isn’t for everyone. If you’re just a casual dieter and don’t really care if you lose
                                weight or not, Commit is not for you.
                            </p>
                            <p>
                                But if you’re sick of feeling fat, want to lose weight once and forever, and are willing to
                                go to an extreme to make it happen, Commit could work for you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="subscribe">
                <div className="container-fluid">
                    <div className="content text-center">
                        <div className="text-c">
                            <h2>Your personal assistant in achieving your goals</h2>
                            <div className="form style1">
                                <p>Get notified when we launch</p>
                                <form action="/">
                                    <div className="box">
                                        <div className="input">
                                            <input type="email" name="email" id="s-email" placeholder="Email Address" />
                                        </div>
                                        <div className="submit">
                                            <input
                                                type="submit"
                                                name="submit"
                                                id="s-submit"
                                                value="Subscribe"
                                                className="btn-c"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
