import React, {useState} from 'react'
import ApiHelper from "../helpers/ApiHelper";

export default function ForgotPassword() {
    const [email, setEmail] = useState();
    const [success, setSuccess] = useState(false);
    return (
        <div>
            {success && <div>A password reset email had been sent to the address you provided</div>}
            {!success && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        ApiHelper.handleForgotPassword(email).then(() => setSuccess(true));
                    }}
                >
                    <div className="box">
                        <div className="item-c input-c">
                            <div className="label">
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="input">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter your Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="submit">
                            <input type="submit" name="submit" id="submit" value="Confirm" className="btn-c" />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
