import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="content">
                    <div className="brand">
                        <Link to="/">
                            <img src="/images/logo.svg" alt="Commit" />
                        </Link>
                    </div>
                    <ul>
                        <li>
                            <Link to="/how-it-works">How it works</Link>
                        </li>
                        <li>
                            <a href="#">Results</a>
                        </li>
                        <li>
                            <Link to="/story">About Us</Link>
                        </li>
                        <li>
                            <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                            <a href="/terms-of-service">Terms of Service</a>
                        </li>
                        <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
