import React, {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

export default function FileUpload({onChange=()=>{}}) {
    const [previewPhoto, setPreviewPhoto] = useState(null);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            onChange(acceptedFiles);
            setPreviewPhoto(acceptedFiles[0]);
        },
    });

    const onClear = () => {
        setPreviewPhoto(null);
        onChange([]);
    };

    if (previewPhoto) {
        return (
            <div className="show-photo">
                <div className="img-c">
                    <i className="iconfont icon-close" onClick={onClear}></i>
                    <img src={`${URL.createObjectURL(previewPhoto)}`} />
                </div>
            </div>
        );;
    }

    return (
        <div {...getRootProps({  className:  "upload"  })}>
            <label htmlFor="photo">
                <i className="iconfont icon-camera"></i>
                <p>
                    
                    <strong>Drag &amp; Drop</strong> <br />
                    
                    your files here, or <u>browse</u>
                
                </p>
            </label>
            <input {...getInputProps({  onChange: (e) => onChange(e.target.files)  })} />
        </div>
    );;
}