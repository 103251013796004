import React from 'react';
import {
  Link,
} from "react-router-dom";

import ForgotPasswordComponent from "../components/ForgotPassword";

export default function ForgotPassword() {
    return (
        <React.Fragment>
            <div class="right-fixed">
                <p>
                    No account? <Link to="/login/signup">Sign Up</Link>
                </p>
            </div>
            <div class="content">
                <div class="text-c">
                    <h2>Forgot Password?</h2>
                    <p>Enter your email address below and we'll get you back on track.</p>
                </div>
                <div class="form style2">
                    <ForgotPasswordComponent />
                </div>
            </div>
        </React.Fragment>
    );
}
