import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";

import FileUpload from "./FileUpload";
import { useLocalStorage, ApiHelper } from "../helpers";

export default function WeighIn() {
    const [commitmentId, setCommitmentId] = useLocalStorage('commitmentId', null);
    const [weighInId, setWeighInId] = useLocalStorage('weighInId', null);
    const [weighInKeyword, setWeighInKeyword] = useLocalStorage('weighInKeyword', null);
    const [s3UploadInfo, setS3UploadInfo] = useState(null);
    const [weighInPhoto, setWeighInPhoto] = useState(null);
    const [loggedIn, setLoggedIn] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (!weighInId && commitmentId) {
            ApiHelper.createWeighIn(commitmentId).then(response => {
               setWeighInId(response.id);
               setWeighInKeyword(response.verification_word);
            });
        }
    }, [commitmentId]);

    useEffect(() => {
        ApiHelper.getMe().catch(error => {
            console.log(error);
            setLoggedIn(false);
        })
        if (!commitmentId) {
            ApiHelper.getMyMostRecentCommitment()
            .then((data) => {
                if (data.id) {
                    setCommitmentId(data.id);
                } else {
                    alert("You do not have a current commitment, reroute to create commitment page");
                }
            })
            .catch(error => {
                console.log(error);
                alert("Error loading your commitment");
            })
        }
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        if (!s3UploadInfo) {
            return;
        }
        const formData = new FormData();
        Object.keys(s3UploadInfo.fields).forEach(key => {
            formData.append(key, s3UploadInfo.fields[key]);
        });
        formData.append("file", weighInPhoto);

        axios.post(s3UploadInfo.url, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }              
        }).then(function () {
            ApiHelper.lockWeighIn(weighInId).then(lockResponse => {
                console.log(lockResponse);
                setWeighInId(null);
                setWeighInKeyword(null);
                history.push("/dashboard");
            });
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const generateUploadUrl = async (fileObj) => {
        if (!fileObj) {
            return;
        }
        setWeighInPhoto(fileObj);
        const filename = fileObj.name;
        const uploadInfo = await ApiHelper.createUploadUrl(filename, weighInId);
        setS3UploadInfo(uploadInfo);
    };

    if (!loggedIn) {
        return (
            <div>
                You need to log in to create a weigh-in.
            </div>
        )
    }

    return (
        <form onSubmit={(e) => submitHandler(e)}>
            <div className="box">
                <div className="input">
                    <input type="text" name="label" id="label" value={weighInKeyword} />
                </div>
                <FileUpload
                    onChange={(files) => {
                        generateUploadUrl(files[0]);
                    }}
                />
                <div className="submit">
                    <input
                        type="submit"
                        name="submit"
                        id="submit"
                        value="Let’s start"
                        className={`btn-c ${weighInPhoto ? "" : "disabled"}`}
                        disabled={!weighInPhoto}
                    />
                </div>
            </div>
        </form>
    );
}
