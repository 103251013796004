import React, {useState} from 'react'
import ApiHelper from "../helpers/ApiHelper";

export default function CommitmentSummary() {

    return (
        <div>
            <h3>Weight loss goal</h3>
            <p>placeholder by placeholderdate - placeholder day(s) left</p>
            <h3>What's at stake</h3>
            <p>$placeholder will go to The Placeholder</p>
            <button>Share my progress</button>
        </div>
    )
}
