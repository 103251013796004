import React, {useRef} from 'react'

export default function NumberInput({id, onChange, defaultValue}) {
    const textBox = useRef(null);

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    const decrement = () => {
        let value = parseInt(textBox.current.value) || 0;
        value = value - 1;
        value = value < 0 ? 0 : value;
        onChange(value);
        textBox.current.value = value;
    }

    const increment = () => {
        let value = parseInt(textBox.current.value) || 0;
        value = value + 1;
        onChange(value);
        textBox.current.value = value;
    }

    return (
        <React.Fragment>
            <input id={id} type="number" ref={textBox} onChange={e => handleChange(e)} defaultValue={defaultValue}/>
            <a href="javascript:void(0);" onClick={increment} className="plus"><img src="/images/plus.svg" /></a>
            <a href="javascript:void(0);" onClick={decrement} className="minus"><img src="/images/minus.svg" /></a>
        </React.Fragment>
    )
}
