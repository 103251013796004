import React, { useContext, useEffect } from "react";
import {
  Link,
} from "react-router-dom";

import { OnboardingContext } from "../contexts";
import { ApiHelper } from "../helpers";

export default function LoginSignup() {
    const { data, actions } = useContext(OnboardingContext);

    useEffect(() => {
        async function checkUserLogin() {
            const userStatus = await ApiHelper.isLoggedIn();
            if (userStatus) {
                window.location.href = "/dashboard";
            }
        }
        checkUserLogin();
    }, []);

    return (
        <React.Fragment>
            <div className="right-fixed">
                <p>Already a member? <Link to="/login/login">Sign In</Link></p>
            </div>
            <div className="content">
                <div className="text-c">
                    <h2>Sign Up for Free</h2>
                </div>
                <div className="form style2">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        actions.processEntryPoint();
                    }}>
                        <div className="box">
                            <div className="two-col">
                                <div className="item-c input-c">
                                    <div className="label"><label htmlFor="firstName">First Name</label></div>
                                    <div className="input"><input defaultValue={data.firstName} type="text" name="firstName" id="firstName" placeholder="Your Name" onChange={e => actions.setFirstName(e.target.value)} /></div>
                                </div>
                                <div className="item-c input-c">
                                    <div className="label"><label htmlFor="lastName">Last Name</label></div>
                                    <div className="input"><input defaultValue={data.lastName} type="text" name="lastName" id="lastName" placeholder="Your Last Name" onChange={e => actions.setLastName(e.target.value)} /></div>
                                </div>
                            </div>
                            <div className="item-c input-c">
                                <div className="label"><label htmlFor="email">Email</label></div>
                                <div className="input"><input type="email" name="email" id="email" placeholder="Enter your Email"  onChange={e => actions.setEmail(e.target.value)} defaultValue={data.email}/></div>
                            </div>
                            <div className="item-c input-c">
                                <div className="label"><label htmlFor="password">Password</label></div>
                                <div className="input"><input type="password" name="password" id="password" placeholder="6+ characters" onChange={e => actions.setPassword(e.target.value)} /></div>
                            </div>
                            <div className="item-c check-c">
                                <input type="checkbox" checked={!!data.privacyPolicyAccepted} id="input-terms-accepted" onChange={e => actions.setPrivacyPolicyAccepted(e.target.checked)}/>
                                <div className="label"><label htmlFor="input-terms-accepted">I accept the <a href="/privacy-policy">privacy policy</a></label></div>
                            </div>
                            <div className="submit"><input type="submit" name="submit" id="submit" value="Create Account" className="btn-c" /></div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
