import React, { useState } from "react";
import { SlideDown } from "react-slidedown";

import categorizedQuestions from "../helpers/categorizedQuestions";

export default function FaqPage() {
    const [activeItem, setActiveItem] = useState(null);
    const groupRefs = categorizedQuestions.map(() => React.createRef());
    return (
        <React.Fragment>
            <div className="faq-guide inner-banner">
                <div className="container-fluid">
                    <div className="content text-center bg-grey">
                        <div className="text-c">
                            <h2>FAQ</h2>
                            {/* <div className="form style1">
                                <form action="/">
                                    <div className="box">
                                        <div className="input">
                                            <input type="search" name="search" id="search" placeholder="Ask a question..." />
                                        </div>
                                        <div className="submit">
                                            <input
                                                type="submit"
                                                name="submit"
                                                id="submit"
                                                value="Search"
                                                className="btn-c"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                            {/* <p>or choose an option category to quickly find the help you need</p> */}
                        </div>
                        <div className="group">
                            {categorizedQuestions.map((category, index) => {
                                return (
                                    <div className="item-c">
                                        <a
                                            href="javascript:void(0);"
                                            key={category.categoryName}
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: groupRefs[index].current.offsetTop - 85,
                                                    behavior: "smooth",
                                                    start: "block",
                                                });
                                            }}
                                        >
                                            <i className={`iconfont ${category.iconClassName}`}></i>
                                            <h3>{category.categoryName}</h3>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq-list">
                <div className="container-fluid">
                    <div className="content">
                        {categorizedQuestions.map((category, index) => {
                            return (
                                <div className="item-c" key={category.id}>
                                    <h3 ref={groupRefs[index]}>{category.categoryName}</h3>
                                    <div className="accordion">
                                        {category.questions.map((quest) => {
                                            const itemStyle = activeItem === quest.id ? { display: "block" } : {};
                                            return (
                                                <div
                                                    className={`item ${activeItem === quest.id ? "is-active" : ""}`}
                                                    key={quest.id}
                                                >
                                                    <div
                                                        className="item-title"
                                                        onClick={() =>
                                                            setActiveItem(activeItem === quest.id ? null : quest.id)
                                                        }
                                                    >
                                                        <a href="javascript:void(0);">{quest.question}</a>
                                                    </div>
                                                    <SlideDown className={"my-dropdown-slidedown"}>
                                                        <div className="item-content" style={itemStyle}>
                                                            <div className="text-c">
                                                                <ul>
                                                                    {quest.response.map((responseLine) => (
                                                                        <li>{responseLine}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </SlideDown>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
