import React, { useEffect, useState } from "react";

import { useLocalStorage, ApiHelper } from "../helpers";
import { useHistory } from "react-router-dom";

const OnboardingContext = React.createContext({
    data: {
        currentStep: "",
        commitStep: 0,

        firstName: "",
        lastName: "",
        email: "",
        password: "",
        privacyPolicyAccepted: false,
        userId: null,
        entryPointFeedback: [],

        age: 0,
        country: "",
        height: 0,
        heightDisplayUnits: "cm",
        weight: 0,
        weightDisplayUnits: "kg",
        sex: "",
        accountFormFeedback: [],

        targetWeight: 0,
        targetWeightDisplayUnits: "kg",
        targetDate: "",
        termsAccepted: false,
        goalFormFeedback: [],

        commitmentAmount: 0,
        commitmentAmountFeedback: [],
        causes: [],
        antiCharity: null,
        fullCausesList: [],
        agreeNonRefundable: false,
        agreeWeighIn: false,
        agreeLockBox: false,
        agreeTermsFinal: false,
    },
    actions: {
        setCurrentStep: () => {},

        setFirstName: () => {},
        setLastName: () => {},
        setEmail: () => {},
        setPassword: () => {},
        setPrivacyPolicyAccepted: () => {},
        setUserId: () => {},

        setAge: () => {},
        setCountry: () => {},
        setHeight: () => {},
        setHeightDisplayUnits: () => {},
        setWeight: () => {},
        setWeightDisplayUnits: () => {},
        setSex: () => {},

        setTargetWeight: () => {},
        setTargetWeightDisplayUnits: () => {},
        setTargetDate: () => {},
        setTermsAccepted: () => {},

        processAccountForm: () => {},
        processEntryPoint: () => {},
        processGoalForm: () => {},
        processCommitmentOne: () => {},
        getWeightLossCalculation: () => {},
        getTargetBmi: () => {},
        getCmHeight: () => {},
        getKgTargetWeight: () => {},
        getKgWeight: () => {},

        setCommitmentAmount: () => {},
        createCommitment: () => {},
        setCauses: () => {},
        setAntiCharity: () => {},
        getOrganizations: () => {},
        finalizeCommitment: () => {},
        setAgreeNonRefundable: () => {},
        setAgreeWeighIn: () => {},
        setAgreeLockBox: () => {},
        setAgreeTermsFinal: () => {},
        getMyAntiCharity: () => {},
        getSuccessStructure: () => {},
    },
});

const OnboardingContextStore = ({ children }) => {
    const history = useHistory();
    const [currentStep, setCurrentStep] = useLocalStorage("currentStep", 1);
    const [commitStep, setCommitStep] = useLocalStorage("commitStep", 0);

    const [firstName, setFirstName] = useLocalStorage('firstName', "");
    const [lastName, setLastName] = useLocalStorage('lastName', "");
    const [email, setEmail] = useLocalStorage('email', "");
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useLocalStorage('privacyPolicyAccepted', false);
    const [password, setPassword] = useState("");
    const [entryPointFeedback, setEntryPointFeedback] = useState([]);

    const [userId, setUserId] = useLocalStorage('userId', null)
    const [age, setAge] = useLocalStorage('age', "");
    const [country, setCountry] = useLocalStorage('country', "");
    const [height, setHeight] = useLocalStorage('height', "");
    const [heightDisplayUnits, setHeightDisplayUnits] = useLocalStorage('heightDisplayUnits', "cm");
    const [weight, setWeight] = useLocalStorage('weight', "");
    const [weightDisplayUnits, setWeightDisplayUnits] = useLocalStorage('weightDisplayUnits', "kg");
    const [sex, setSex] = useLocalStorage('sex', "male");
    const [accountFormFeedback, setAccountFormFeedback] = useState([]);

    const [targetWeight, setTargetWeight] = useLocalStorage('targetWeight', false);
    const [targetWeightDisplayUnits, setTargetWeightDisplayUnits] = useLocalStorage('targetWeightDisplayUnits', false);
    const [targetDate, setTargetDate] = useLocalStorage('targetDate', false);
    const [termsAccepted, setTermsAccepted] = useLocalStorage('termsAccepted', false);
    const [goalFormFeedback, setGoalFormFeedback] = useState([]);

    const [commitmentAmount, setCommitmentAmount] = useLocalStorage('commitmentAmount', false);
    const [commitmentAmountFeedback, setCommitmentAmountFeedback] = useState([]);
    const [clientSecret, setClientSecret] = useLocalStorage('clientSecret', false);
    const [commitmentId, setCommitmentId] = useLocalStorage('commitmentId', false);
    const [causes, setCauses] = useLocalStorage('causes', []);
    const [antiCharity, setAntiCharity] = useLocalStorage('antiCharity', null);
    const [fullCausesList, setFullCausesList] = useLocalStorage('fullCausesList', []);
    const [agreeNonRefundable, setAgreeNonRefundable] = useLocalStorage(false);
    const [agreeWeighIn, setAgreeWeighIn] = useLocalStorage(false);
    const [agreeLockBox, setAgreeLockBox] = useLocalStorage(false);
    const [agreeTermsFinal, setAgreeTermsFinal] = useLocalStorage(false);

    useEffect(() => {
        ApiHelper.getCauses().then(causes => {
            setFullCausesList(causes);
        });
    }, []);

    const processEntryPoint = () => {
        const feedback = [];
        if (!email || !email.length || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
            feedback.push("Please make sure you enter a valid email address");
        }
        if (!password || password.length < 6) {
            feedback.push("Please choose a password that is at least 6 characters long");
        }
        if (!firstName || !firstName.length) {
            feedback.push("Please be sure to enter your first name");
        }
        if (!lastName || !lastName.length) {
            feedback.push("Please be sure to enter your last name");
        }
        if (!privacyPolicyAccepted) {
            feedback.push("Please accept our privacy policy to continue");
        }
        setEntryPointFeedback(feedback);
        if (feedback.length) {
            return;
        }
        const userData = {
            email,
            id: userId,
            email,
            first_name: firstName,
            last_name: lastName,
            password,
        };
        ApiHelper.registerOrUpdate(userData).then(user => {
            console.log(user);
            setUserId(user.id);
            console.log('User is updated or signed up!');
            setCurrentStep(1);
            window.location.href = "/onboarding"
        });
    }

    const getKgWeight = () => {
        return weightDisplayUnits === 'kg' ? weight :  (weight / 2.2);
    }

    const getCmHeight = () => {
        return heightDisplayUnits === "cm" ? height : height * 2.5;
    };

    const getTargetBmi = () => {
        const meterHeight = getCmHeight() / 100;
        return getKgTargetWeight() / (meterHeight * meterHeight);
    };

    const getKgTargetWeight = () => {
        return weightDisplayUnits === 'kg' ? targetWeight :  (targetWeight / 2.2);
    }

    const processAccountForm = () => {
        const feedback = [];
        if (!country || !country.length) {
            feedback.push({ field: "country", message: "Please select a country" });
        }
        if (!age || !parseInt(age)) {
            feedback.push({ field: "age", message: "Please enter a valid age" });
        }
        if (!height || !parseInt(height)) {
            feedback.push({ field: "height", message: "Please enter a valid height" });
        }
        if (!weight || !parseInt(weight)) {
            feedback.push({ field: "weight", message: "Please enter a valid weight" });
        }
        setAccountFormFeedback(feedback);
        if (feedback.length) {
            return;
        }
        const userData = {
            email,
            id: userId,
            age,
            country,
            height: getCmHeight(),
            weight: getKgWeight(),
            display_height_units: heightDisplayUnits,
            display_weight_units: weightDisplayUnits,
            sex,
        };
        ApiHelper.registerOrUpdate(userData).then(user => {
            console.log(user);
            setUserId(user.id);
            console.log('User is updated or signed up!');
        });
        setCurrentStep(2);
    }

    const processGoalForm = () => {
        const targetDateObject = new Date(targetDate);
        const now = new Date();
        const feedback = [];
        if (!targetDate || !targetDate.length) {
            feedback.push({ field: "targetDate", message: "Please set a target date for your goal" });
        } else if (targetDateObject <= now) {
            feedback.push({ field: "targetDate", message: "The target date for your goal should be in the future" });
        }
        if (!targetWeight || !parseFloat(targetWeight)) {
            feedback.push({ field: "targetWeight", message: "Please set a target weight for your goal" });
        } else if (parseFloat(targetWeight) >= parseFloat(weight)) {
            feedback.push({ field: "targetWeight", message: "Your target weight should be less than your current weight." });
        }

        setGoalFormFeedback(feedback);
        if (feedback.length) {
            return;
        }
        setCurrentStep(3);
    }

    const processCommitmentOne = () => {
        const feedback = [];
        if (!commitmentAmount ||!commitmentAmount.length) {
            feedback.push("Please set a monetary amount for your commitment");
        } else if (parseInt(commitmentAmount) < 100) {
            feedback.push("The minimum commitment amount is $100");
        }
        setCommitmentAmountFeedback(feedback);
        if (feedback.length) {
            return;
        }
        setCurrentStep(3);
        setCommitStep(1);
    }

    const createCommitment = () => {
        const targetDateObject = new Date(targetDate);
        const now = new Date();
        if (commitmentId) {
            ApiHelper.updateCommitment(commitmentId,
                                       commitmentAmount, 
                                       getKgTargetWeight(),
                                       getKgWeight(),
                                       targetDateObject.toISOString(),
                                       now.toISOString(),
                                       antiCharity).
            then(function(responseJson) {
                console.log(responseJson)
                var clientSecretResponse = responseJson.client_secret;
                setClientSecret(clientSecretResponse);
            });
            setCurrentStep(4);
            return;
        }
        ApiHelper.createCommitment(commitmentAmount, 
                                   getKgTargetWeight(),
                                   getKgWeight(),
                                   targetDateObject.toISOString(),
                                   now.toISOString(),
                                   antiCharity).
        then(function(responseJson) {
            console.log(responseJson)
            var clientSecretResponse = responseJson.client_secret;
            var commitmentIdResponse = responseJson.id;
            setCommitmentId(commitmentIdResponse);
            setClientSecret(clientSecretResponse);
        });
        setCurrentStep(4);
    }

    const getWeightLossCalculation = () => {
        const longUnits = weightDisplayUnits === "kg" ? "kilos" : "pounds";
        if (!weight || !targetWeight) {
            return {
                target: null,
                longUnits,
                targetBmi: null,
                targetBmiTooLow: null,
                weightGain: null,
                weightLossDays: null,
                weightLossWeeks: null,
                weightLossPerWeek: null,
                weightLossTooFast: null,
                errorState: false,
            };
        }
        const targetBmi = getTargetBmi();
        const targetBmiTooLow = targetBmi < 18.5;
        const target = (weight - targetWeight);
        const kgTarget = getKgWeight() - getKgTargetWeight();
        const weightGain = target <= 0;
        const targetDateObject = new Date(targetDate);
        const now = new Date();
        let weightLossPerWeek = 0.5;
        let weightLossDays = 0;
        let weightLossWeeks = 0;
        if (targetDate) {
            const weightLossMillisecs = targetDateObject.getTime() - now.getTime();
            weightLossDays = Math.round(weightLossMillisecs / (1000 * 3600 * 24));
            weightLossWeeks = Math.round(weightLossDays / 7);
            weightLossPerWeek = kgTarget / weightLossWeeks;
        }
        const weightLossTooFast = weightLossPerWeek > 1;
        return {
            target,
            longUnits,
            targetBmi,
            targetBmiTooLow,
            weightGain,
            weightLossDays,
            weightLossWeeks,
            weightLossPerWeek,
            weightLossTooFast,
            errorState: targetBmiTooLow || weightGain || weightLossTooFast,
        };
    }

    const getOrganizations = () => {
        return causes.map((id) => {
            return fullCausesList.find(c => c.id === id);
        }).reduce((prev, cause) => {
            return [...prev, ...cause.organizations.data];
        }, []);
    }

    const getMyAntiCharity = (id = null) => {
        const antiCharityId = id || antiCharity;
        const orgs = getOrganizations();
        return orgs.find((o) => o.id === antiCharityId);
    };

    const getSuccessStructure = () => {
        const minimumFee = 50;
        const maximumFee = 2000;
        if (!commitmentAmount) {
            return null;
        }
        const total = parseInt(commitmentAmount);
        let successFee = 0.1 * total;
        successFee = successFee < minimumFee ? minimumFee : successFee;
        successFee = successFee > maximumFee ? maximumFee : successFee;
        successFee = Math.round(successFee);
        const refund = total - successFee;
        return { total, successFee, refund };
    };

    const finalizeCommitment = async (result) => {
        // need to update commitment with transaction id
        console.log("🎉🎉🎉🎉🎉🎉");
        console.log(result);
        history.push("/success/success");
    };

    const value = {
        data: {
            currentStep,
            commitStep,

            firstName,
            lastName,
            email,
            password,
            privacyPolicyAccepted,
            userId,
            entryPointFeedback,
            accountFormFeedback,

            age,
            country,
            height,
            heightDisplayUnits,
            weight,
            weightDisplayUnits,
            sex,

            targetWeight,
            targetWeightDisplayUnits,
            targetDate,
            termsAccepted,
            goalFormFeedback,

            commitmentAmount,
            commitmentAmountFeedback,
            causes,
            antiCharity,
            clientSecret,
            commitmentId,
            fullCausesList,
            agreeNonRefundable,
            agreeWeighIn,
            agreeLockBox,
            agreeTermsFinal,
        },
        actions: {
            setCurrentStep,
            setCommitStep,

            setFirstName,
            setLastName,
            setEmail,
            setPassword,
            setPrivacyPolicyAccepted,
            setUserId,

            setAge,
            setCountry,
            setHeight,
            setHeightDisplayUnits,
            setWeight,
            setWeightDisplayUnits,
            setSex,

            setTargetWeight,
            setTargetWeightDisplayUnits,
            setTargetDate,
            setTermsAccepted,

            processAccountForm,
            processEntryPoint,
            processGoalForm,
            processCommitmentOne,
            getWeightLossCalculation,
            getTargetBmi,
            getCmHeight,
            getKgTargetWeight,
            getKgWeight,

            setCommitmentAmount,
            setCauses,
            setAntiCharity,
            createCommitment,
            getOrganizations,
            finalizeCommitment,
            setAgreeNonRefundable,
            setAgreeWeighIn,
            setAgreeLockBox,
            setAgreeTermsFinal,
            getMyAntiCharity,
            getSuccessStructure,
        },
    };

    return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export default OnboardingContextStore;

export { OnboardingContext };
