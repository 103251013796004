import React, { useContext, useEffect, useState } from "react";

import { OnboardingContext } from "../contexts";

// import styles from "./Onboarding.module.scss";

const CommitmentAnticharity = () => {
    const { data, actions } = useContext(OnboardingContext);
    const [orgList, setOrgList] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(data.antiCharity);
    const [antiCharityObj, setAntiCharityObj] = useState(null);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const localOrgList = actions.getOrganizations();
        const localSelected = localOrgList.map(org => org.id).includes(selectedOrg) ? selectedOrg : null;
        setOrgList(localOrgList);
        setSelectedOrg(localSelected);
        if (selectedOrg) {
            setAntiCharityObj(actions.getMyAntiCharity());
        }
    }, [data.causes]);

    const updateStatus = (id, status) => {
        setShowError(false);
        if (status) {
            setSelectedOrg(id);
            actions.setAntiCharity(id);
            setAntiCharityObj(actions.getMyAntiCharity(id));
        }
    }

    if (data.currentStep !== 3 || data.commitStep !== 2) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="before-btns">
                <div className="text-c">
                    <h2>Choose your anti-charity</h2>
                    <p>
                        Now, here are organizations you probably don’t like very much. <br />
                        You don’t want to give some of these a single penny.
                    </p>
                </div>
                <div className="form style2">
                    <div className="nemesis">
                        <p>
                            <strong>Choose your commitment nemesis: </strong>
                        </p>
                        <div className="nemesis-choose">
                            <p className="name">{antiCharityObj && antiCharityObj.name}</p>
                        </div>
                    </div>
                    <form action="" method="post">
                        <div className="box">
                            <div className="item-c multi-check two-col">
                                <div className="multi-box mCustomScrollbar">
                                    {orgList.map((org) => {
                                        return (
                                            <div className="choose" key={org.id}>
                                                <input
                                                    type="radio"
                                                    name="anti_charity_input"
                                                    value={org.id}
                                                    id={org.id}
                                                    checked={org.id === selectedOrg}
                                                    onChange={(e) => {
                                                        updateStatus(org.id, e.target.checked);
                                                    }}
                                                />
                                                <div className="label">
                                                    <label htmlFor={org.id}>
                                                        <img src="images/label-logo.svg" />
                                                        <span>{org.name}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="prev-next">
                <a onClick={() => actions.setCommitStep(1)} className="btn-c prev">
                    Previous
                </a>
                <a
                    disabled={!antiCharityObj || !data.antiCharity}
                    onClick={() => {
                        if (!antiCharityObj || !data.antiCharity) {
                            setShowError(true);
                            return;
                        }
                        actions.createCommitment()
                    }}
                    className="btn-c next">
                    Confirm
                </a>
            </div>
            {showError && (
                <div className="onboarding-error-feedback">
                    <ul>
                        <li>Please select your anti-charity to continue</li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default CommitmentAnticharity;
