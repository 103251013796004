import React, { useEffect } from 'react'
import {
    Switch,
    Route,
} from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

import Homepage from "../pages/Homepage";
import HowItWorks from '../pages/HowItWorks';
import StoryPage from "../pages/StoryPage";
import FaqPage from "../pages/FaqPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";

export default function MainLayout(props) {
    useEffect(() => {
        document.getElementById('root').classList.add('fixed-width');
        return () => {
            document.getElementById('root').classList.remove('fixed-width');
        }
    }, [])
    return (
        <div>
            <Header />
            <Switch>
                <Route exact path="/" render={(props) => <Homepage {...props} />} />
                <Route exact path="/how-it-works" render={(props) => <HowItWorks {...props} />} />
                <Route exact path="/story" render={(props) => <StoryPage {...props} />} />
                <Route exact path="/faq" render={(props) => <FaqPage {...props} />} />
                <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                <Route exact path="/terms-of-service" render={(props) => <TermsOfService {...props} />} />
            </Switch>
            <Footer />
        </div>
    );
}
