import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [responsiveNavOpen, setResponsiveNavOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 10) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.addEventListener("scroll", handleScroll);
    }, []);

    
    return (
        <header className={`header-black ${scrolled ? 'smaller' : ''}`}>
            <div className="container">
                <div className="content">
                    <div className="brand">
                        <h1>
                            <Link to="/">
                                <span style={{ display: "none" }}>Commit</span>
                                <img src="/images/logo.svg" alt="Commit" />
                            </Link>
                        </h1>
                    </div>
                    <nav className={`${responsiveNavOpen ? 'open' : ''}`}>
                        <div className={`nav-c ${responsiveNavOpen ? 'open' : ''}`}>
                            <ul>
                                <li>
                                    <Link to="/how-it-works">How it works</Link>
                                </li>
                                <li>
                                    <Link to="/story">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/faq">FAQ</Link>
                                </li>
                            </ul>
                            <Link to="/login/login" className="btn-c">
                                Log In
                            </Link>
                        </div>
                    </nav>
                    <div className={`burger-c ${responsiveNavOpen ? 'open' : ''}`} onClick={() => {setResponsiveNavOpen(!responsiveNavOpen)}}>
                        <div className="burger">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
