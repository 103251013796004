import React, { useContext, useState, useEffect } from "react";

import { OnboardingContext } from "../contexts";

const CommitmentCauses = () => {
    const { data, actions } = useContext(OnboardingContext);
    const [statusList, setStatusList] = useState({});
    const [showError, setShowError] = useState(false);
    
    useEffect(() => {
        const causeStatuses = {};
        data.fullCausesList.forEach((cause) => causeStatuses[cause.id] = data.causes.includes(cause.id));
        setStatusList(causeStatuses);
    }, []);

    const updateStatus = (id, status) => {
        setShowError(false);
        const {...localStatuses} = statusList;
        localStatuses[id] = status;
        setStatusList(localStatuses);
        actions.setCauses(Object.keys(localStatuses).filter(key => localStatuses[key]));
    }

    if (data.currentStep !== 3 || data.commitStep !== 1) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="before-btns">
                <div className="text-c">
                    <h2>What you like 😍</h2>
                    <p>Now we’ll help you find your commitment nemesis.</p>
                </div>
                <div className="form style2">
                    <div className="nemesis">
                        <p><strong>To start, choose things that you LIKE or that make you feel happy:</strong></p>
                    </div>
                    <form action="" method="post">
                        <div className="box">
                            <div className="item-c multi-check">
                                <div className="multi-box mCustomScrollbar">
                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}


                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.fullCausesList.map(cause => {
                                    return (
                                        <div className="choose">
                                            <input
                                                type="checkbox"
                                                name="causes_input"
                                                value={cause.id}
                                                id={cause.id}
                                                checked={statusList[cause.id]}
                                                onChange={e => {
                                                    updateStatus(cause.id, e.target.checked);
                                                }}
                                                />
                                            <div className="label">
                                                <label htmlFor={cause.id}>{cause.name}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="prev-next">
                <a onClick={() => actions.setCommitStep(0)} className="btn-c prev">Previous</a>
                <a
                    disabled={!data.causes.length}
                    onClick={() => {
                        if (!data.causes.length) {
                            setShowError(true);
                            return;
                        }
                        actions.setCommitStep(2);
                    }}
                    className="btn-c next">Confirm</a>
            </div>
            {showError && (
                <div className="onboarding-error-feedback">
                    <ul>
                        <li>Please select at least one cause to continue</li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default CommitmentCauses;
