import React, { useContext, useState } from "react";
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';

import { OnboardingContext } from "../contexts";

// import styles from "./Onboarding.module.scss";

const FinalConfirmation = () => {
    const { data, actions } = useContext(OnboardingContext);
    const stripe = useStripe();
    const elements = useElements();
    const weightLossInfo = actions.getWeightLossCalculation()

    const styles = (data.currentStep === 5) ? {} : {display: "none"};

    const targetDate = new Date(data.targetDate);
    const antiCharity = actions.getMyAntiCharity();
    const feeStructure = actions.getSuccessStructure();
    const [showError, setShowError] = useState(false);

    const finalize = async (e) => {
        e.preventDefault();
        if (!(data.agreeNonRefundable && data.agreeWeighIn && data.agreeLockBox && data.agreeTermsFinal)) {
            setShowError(true);
            return;
        }
        if (!stripe) {
            return;
        }
    
        const result = await stripe.confirmCardPayment(data.clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
            }
        });
    
        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                actions.finalizeCommitment(result);
            }
        } 
    }
    
    return (
        <div>
            <div className="before-btns" style={styles}>
                <div className="commitment">
                    <div className="title-box text-center">
                        <h2>Your Commitment</h2>
                        <p>
                            Lose <strong>{weightLossInfo.target}</strong> {weightLossInfo.longUnits} by 📆{" "}
                            <strong> {targetDate.toLocaleDateString()}</strong>
                        </p>
                    </div>
                    <div className="end-weight">
                        <div className="group">
                            <div className="item-c fail">
                                <div className="text-c">
                                    <img src="images/icon-cancel.svg" alt="" />
                                    <div className="box">
                                        <h3>If you fail,</h3>
                                        <p>
                                            {" "}
                                            ${data.commitmentAmount} will be donated to {antiCharity && antiCharity.name} in
                                            your name
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item-c win">
                                <div className="text-c">
                                    <img src="images/icon-checked.svg" alt="" />
                                    <div className="box">
                                        <h3>If you win,</h3>
                                        {feeStructure &&
                                            <p>
                                                you’ll get back ${feeStructure.refund} (We’ll take ${feeStructure.successFee} as
                                                a success award to keep improving Commit)
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form style2">
                    <form action="" method="post">
                        <div className="box">
                            <p>
                                <strong>Agree to terms</strong>
                            </p>
                            <div className="item-c check-c">
                                <input
                                    type="checkbox"
                                    checked={data.agreeNonRefundable}
                                    id="agree-non-refundable"
                                    onChange={(e) => actions.setAgreeNonRefundable(e.target.checked)}
                                />
                                <div className="label">
                                    <label htmlFor="agree-non-refundable">
                                        Until the end date of your commitment ({targetDate.toLocaleDateString()}), your funds
                                        are not refundable for any reason, even if you regret it an hour from now. (If you
                                        knew you could get your money back, this wouldn’t work.)
                                    </label>
                                </div>
                            </div>
                            <div className="item-c check-c">
                                <input
                                    type="checkbox"
                                    checked={data.agreeWeighIn}
                                    id="agree-weigh-in"
                                    onChange={(e) => actions.setAgreeWeighIn(e.target.checked)}
                                />
                                <div className="label">
                                    <label htmlFor="agree-weigh-in">
                                        You will follow the{" "}
                                        <a href="#" className="turn-on-pop">
                                            weigh-in procedures
                                        </a>{" "}
                                        to start and end your commitment. If we don’t get your weigh-in by 14 days after your
                                        goal, we will have to release your commitment to your nemesis.
                                    </label>
                                </div>
                            </div>
                            <div className="item-c check-c">
                                <input
                                    type="checkbox"
                                    checked={data.agreeLockBox}
                                    id="agree-lock-box"
                                    onChange={(e) => actions.setAgreeLockBox(e.target.checked)}
                                />
                                <div className="label">
                                    <label htmlFor="agree-lock-box">
                                        We will hold your commitment in a lockbox account and will not touch it until the end
                                        date of your commitment. As long as we do what we commit to, you agree not to sue us
                                        and you release us from liability.
                                    </label>
                                </div>
                            </div>
                            <div className="item-c check-c">
                                <input
                                    type="checkbox"
                                    checked={data.agreeTermsFinal}
                                    id="agree-terms-final"
                                    onChange={(e) => actions.setAgreeTermsFinal(e.target.checked)}
                                />
                                <div className="label">
                                    <label htmlFor="agree-terms-final">
                                        You agree to our <a href="/terms-of-service" target="_blank">terms of service</a>.
                                    </label>
                                </div>
                            </div>
                            <div className="item-c check-c check-all">
                                <input
                                    checked={
                                        data.agreeNonRefundable &&
                                        data.agreeWeighIn &&
                                        data.agreeLockBox &&
                                        data.agreeTermsFinal
                                    }
                                    onChange={() => {
                                        const newValue = !(
                                            data.agreeNonRefundable &&
                                            data.agreeWeighIn &&
                                            data.agreeLockBox &&
                                            data.agreeTermsFinal
                                        );
                                        actions.setAgreeNonRefundable(newValue);
                                        actions.setAgreeWeighIn(newValue);
                                        actions.setAgreeLockBox(newValue);
                                        actions.setAgreeTermsFinal(newValue);
                                    }}
                                    type="checkbox"
                                    name="checkall"
                                    id="checkall"
                                />
                                <div className="label">
                                    <label htmlFor="checkall">Agree to All</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="prev-next" style={styles}>
                <button onClick={() => actions.setCurrentStep(4)} className="btn-c prev">
                    Previous
                </button>
                <button
                    onClick={(e) => finalize(e)}
                    disabled={!(data.agreeNonRefundable && data.agreeWeighIn && data.agreeLockBox && data.agreeTermsFinal)}
                    className={`btn-c next ${(data.agreeNonRefundable && data.agreeWeighIn && data.agreeLockBox && data.agreeTermsFinal) ? '' : 'disabled'}`}
                >
                    Start commitment
                </button>
            </div>
            {showError && (
                <div className="onboarding-error-feedback">
                    <ul>
                        <li>You must agree to the terms to start your commitment</li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FinalConfirmation;
