import React from 'react'

export default function Progressbar({steps = [], currentStep=1}) {
    return (
        <div className="progress-bar">
            <div className="bar">
                <ul>
                    {steps.map((step, i) => {
                        const stepClass = (i + 1) === currentStep ? 'active show' : '';
                        return (<li className={stepClass} key={i}><a>{step}</a></li>);
                    })}
                </ul>
            </div>
            <ul>
                {steps.map((step, i) => {
                    const stepClass = (i + 1) === currentStep ? 'active show' : '';
                    return (<li className={stepClass} key={i}><a>{step}</a></li>);
                })}
            </ul>
        </div>
    )
}
