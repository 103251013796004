import React, { useState } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { OnboardingContextStore } from "../contexts";
import Onboarding from "../components/Onboarding";

export default function OnboardingLayout() {
    return (
        <OnboardingContextStore>
            <Onboarding />
        </OnboardingContextStore>
    )
}
