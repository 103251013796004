import axios from "axios";

export const randomString = (length = 8) => {
    return Math.random().toString(16).substr(2, length);
};

class ApiHelperClass {
    constructor() {
        const token = window.localStorage.getItem("commitApiToken");
        this._token = token ? JSON.parse(token) : "";
        this._type = "ApiHelperClass";
    }

    set token(value) {
        this._token = value;
        window.localStorage.setItem("commitApiToken", JSON.stringify(value));
    }

    get token() {
        return this._token;
    }

    static getUrl(endpoint) {
        return `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;
    }

    getHeaders() {
        const headers = {
            "Content-Type": "application/json",
        };
        if (this._token.length >= 1) {
            const encodedToken = btoa(`${this._token}:`);
            headers.Authorization = `Basic ${encodedToken}`;
        }
        return headers;
    }

    post(endpoint, data = {}) {
        const options = {
            method: "POST",
            url: ApiHelperClass.getUrl(endpoint),
            headers: this.getHeaders(),
            data,
        };

        return axios
            .request(options)
            .then(function (response) {
                // console.log(response.data);
                return response.data;
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    getEndpoint(endpoint) {
        const options = {
            method: "GET",
            url: ApiHelperClass.getUrl(endpoint),
            headers: this.getHeaders(),
        };

        return axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
                return response.data;
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    register(userData) {
        return this.post("/auth/register", userData).then((jsonData) => {
            this.token = jsonData.data.token;
            return jsonData;
        });
    }

    async isLoggedIn() {
        if (!this.token) {
            return false;
        }
        try {
            const response = await this.getEndpoint("/users/me");
            if (response.data.id) {
                window.localStorage.setItem("userId", JSON.stringify(response.data.id));
                return true;
            }
            return false;
        } catch (e) {
            this.token = null;
            return false;
        }
    }

    login(email, password) {
        return this.post("/auth/login", { email, password }).then((jsonData) => {
            this.token = jsonData.data.token;
            window.localStorage.setItem("userId", JSON.stringify(jsonData.data.user.id));
            return jsonData;
        });
    }

    updateUser(userData) {
        return this.post("/users/edit", userData);
    }

    // Decide on whether we are registering a new user or updating an
    // existing user based on having an id in the data, then return a
    // uniform user object
    registerOrUpdate(userData) {
        if (userData.id && userData.id.length > 0) {
            const { id, email, ...postData } = userData;
            return this.updateUser(postData).then((data) => data.data);
        }
        const { id, ...postData } = userData;
        // If we register a user without a password, just generate one,
        // they will be able to use password reset to log in
        postData.password = postData.password && postData.password.length ? postData.password : randomString(12);
        return this.register(postData).then((jsonData) => jsonData.data.user);
    }

    getCauses() {
        return this.getEndpoint("/causes").then((jsonData) => jsonData.data);
    }

    createCommitment(commitment_amount, target_weight, start_weight, target_date, terms_agreed_to, organization_id) {
        const postBody = {
            commitment_amount,
            target_weight,
            start_weight,
            target_date,
            terms_agreed_to,
            organization_id,
        };
        return this.post("/commitments/", postBody).then((responseJson) => responseJson.data);
    }

    updateCommitment(localCommitmentId, commitment_amount, target_weight, start_weight, target_date, terms_agreed_to, organization_id) {
        const postBody = {
            commitment_amount,
            target_weight,
            start_weight,
            target_date,
            terms_agreed_to,
            organization_id,
        };
        return this.post(`/commitments/${localCommitmentId}`, postBody).then((responseJson) => responseJson.data);
    }

    async createUploadUrl(filename, weighInId) {
        return this.post("/weighins/upload-url", {
            file_name: filename,
            weigh_in_id: weighInId,
        }).then((response) => {
            console.log(response.data);
            return response.data;
        });
    }

    async createWeighIn(commitment_id) {
        return this.post(`/weighins/commitment/${commitment_id}`).then((response) => {
            return response;
        });
    }

    async lockWeighIn(weighin_id) {
        return this.post(`/weighins/${weighin_id}/lock`).then((response) => {
            return response;
        });
    }

    async handleForgotPassword(email) {
        return this.post(`/auth/password/reset`, { email });
    }

    async resetPassword(reset_token, password) {
        return this.post(`/auth/password/reset/confirm`, { reset_token, password });
    }

    async getMe() {
        return this.getEndpoint("/users/me").then((jsonData) => jsonData.data);
    }

    async getOrgs() {
        return this.getEndpoint("/causes/organizations").then((jsonData) => jsonData.data);
    }

    async getMyMostRecentCommitment() {
        return this.getEndpoint("/commitments/").then((jsonData) => jsonData.data);
    }
}

export const ApiHelper = new ApiHelperClass();

export default ApiHelper;